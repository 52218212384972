
const API = (() => {

    /**
     * @param {*} service endpoint
     * @param {*} params URLSearchParams or array of request parameters
     * @param {*} options Request options
     * @param {*} responseType defines what to resolve json (default), text,  blob, 
     */
    const pull = async (service, params, options, responseType = "json") => {

        const defaults = {
            method: 'get',
        };

        // merge options to defaults
        let opt = { ...defaults, ...options };

        let url = service;

        if (opt.method?.toLowerCase() === "post") {

            // set request body
            opt.body = new URLSearchParams(params);

        } else { // GET

            if (params) {

                let searchParams;
                // prepare request options
                // regarding the params datatype
                if (params instanceof URLSearchParams) {
                    searchParams = params;
                } else if (Array.isArray(params)) {
                    searchParams = new URLSearchParams(params);
                } else {
                    searchParams = new URLSearchParams(params);
                }

                // set get params
                url += '?' + searchParams.toString();
            }
        }

        // create request
        const request = new Request(url, opt);

        return fetch(request)
            .then(async (response) => {

                if (!response.ok) {
                    throw new Error(`Network Error: ${response.status} ${response.statusText}`);
                }

                let data;

                switch (responseType) {
                    case 'json':
                        data = await response.json();
                        break;
                    
                    case 'blob':
                        data = await response.blob();
                        break;
                    case 'text': //fall-through
                    default:
                        data = response.text();
                        break;
                }

                return data;

            }).catch((error) => {
                throw error;
            });
    };

    return {
        pull
    };
})();


export default API;