import $ from 'jquery';
import 'jquery-once';
import 'jquery-ui/ui/widgets/autocomplete';
import API from './api';


const autocompleteSplitValues = (value) => {
  let result = [];
  let quote = false;
  let current = '';
  let valueLength = value.length;
  let character = void 0;

  for (let i = 0; i < valueLength; i++) {
    character = value.charAt(i);
    if (character === '"') {
      current += character;
      quote = !quote;
    } else if (character === ',' && !quote) {
      result.push(current.trim());
      current = '';
    } else {
      current += character;
    }
  }
  if (value.length > 0) {
    result.push($.trim(current));
  }

  return result;
}

const extractLastTerm = (terms) => Autocomplete.splitValues(terms).pop();


const searchHandler = (event) => {
  const options = Autocomplete.options;

  if (options.isComposing) {
    return false;
  }

  var term = Autocomplete.extractLastTerm(event.target.value);
  if (term.length > 0 && options.firstCharacterBlacklist.indexOf(term[0]) !== -1) {
    return false;
  }

  return term.length >= options.minLength;
}

const sourceData = async function (request, response)  {
  const elementId = this.element.attr('id');

  if (!(elementId in Autocomplete.cache)) {
    Autocomplete.cache[elementId] = {};
  }

  function showSuggestions(suggestions) {
    const tagged = Autocomplete.splitValues(request.term);
    for (var i = 0; i < tagged.length; i++) {
      const index = suggestions.indexOf(tagged[i]);
      if (index >= 0) {
        suggestions.splice(index, 1);
      }
    }
    response(suggestions);
  }

  const term = Autocomplete.extractLastTerm(request.term);

  const sourceCallbackHandler = (data) => {
    Autocomplete.cache[elementId][term] = data;

    showSuggestions(data);
  }

  if (Autocomplete.cache[elementId].hasOwnProperty(term)) {
    showSuggestions(Autocomplete.cache[elementId][term]);
  } else {

    try {
      const data = await API.pull(this.element.attr('data-autocomplete-path'), { q: term });
      sourceCallbackHandler(data);
    } catch (error) {
      console.error(error);
    }



    /* let options = $.extend({ success: sourceCallbackHandler, data: { q: term } }, Autocomplete.ajax);
    $.ajax(this.element.attr('data-autocomplete-path'), options); */
  }
};

const focusHandler = () => false;

const selectHandler = (event, ui) => {
  let terms = Autocomplete.splitValues(event.target.value);

  terms.pop();

  terms.push(ui.item.value);

  event.target.value = terms.join(', ');

  return false;
}

const renderItem = (ul, item) => $('<li>').append($('<a>').html(item.label)).appendTo(ul);


const Autocomplete = {
  cache: {},

  splitValues: autocompleteSplitValues,
  extractLastTerm: extractLastTerm,

  options: {
    source: sourceData,
    focus: focusHandler,
    search: searchHandler,
    select: selectHandler,
    renderItem: renderItem,
    minLength: 1,

    firstCharacterBlacklist: '',

    isComposing: false
  },
  ajax: {
    dataType: 'json'
  },

  initialize: () => {
    let $autocomplete = $('input.form-autocomplete').once('autocomplete');
    
    if ($autocomplete.length) {
      if ($autocomplete.attr('name') === 'search_api_fulltext') {
        Autocomplete.options.minLength = 3;
      }
      const blacklist = $autocomplete.attr('data-autocomplete-first-character-blacklist');
      $.extend(Autocomplete.options, {
        firstCharacterBlacklist: blacklist || ''
      });
  
      $autocomplete.autocomplete(Autocomplete.options).each(function () {
        $(this).data('ui-autocomplete')._renderItem = Autocomplete.options.renderItem;
      });
  
      $autocomplete.on('compositionstart.autocomplete', function () {
        Autocomplete.options.isComposing = true;
      });
      $autocomplete.on('compositionend.autocomplete', function () {
        Autocomplete.options.isComposing = false;
      });
    }
  }


};

export default Autocomplete;

