import $ from 'jquery';

/**
 * ResultsSorter class
 * 
 * @param {*} container jquery element 
 * @param {*} callback function passed to the event handler
 */
const ResultsSorter = class {

    #sortItemSelector = 'table thead th a';
    sorters; 

    constructor (container, callback) {
        this.container = container;
        this.callback = callback;
    };

    /**
     * 
     */
    initialize = () => {

        if (this.container?.length) {

            this.sorters = this.container.find(this.#sortItemSelector);
    
            if (this.sorters.length) {
    
                // bind event handler on paginator anchor elements click event
                this.bindEvents(this.sorters);
            }

        } else {
            throw new Error('ResultsSorter: container not defined');
        }

    };

    /**
     * bind click event and define handler
     * @param {*} sorters anchor elements of pagination '.pager__item a
     */
    bindEvents = (sorters) => {

        sorters.on('click', this.handleEvent)
    };

    /**
     * click event handler
     * @param {*} event 
     */
    handleEvent = (event) => {
        event.preventDefault();

        let $sorter = $(event.currentTarget);

        if (this.callback instanceof Function) {
            // TODO: @til what info to be processed by callback. page.href = api url or api path defined somewhere and parse page info from page.href?
            this.callback($sorter.attr('href'));
        } else {
            throw new Error('ResultsSorter: no callback of type function');
        }

    };

};

export default ResultsSorter;