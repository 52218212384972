export const decodeUnicode = (str) => {
    return str.replace(/\\u[\dA-F]{4}/gi, (match) => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)));
};


/**
 * timeToMilliseconds
 * @param {String} duration string eg.: '3.5s'
 * @returns milliseconds [number] -> 3500 
 *  
 */
export const timeToMilliseconds = duration => {
    var time = 0;

    // Is duration expressed in seconds
    if (duration.search('s')) {
        // Remove the letter 's' leaving only the number characters
        var durationNumberOnly = duration.replace('s', '');

        if (durationNumberOnly) {
            // Convert the remaining characters into a number
            var durationNumber = parseFloat(durationNumberOnly);

            if (!isNaN(durationNumber)) {
                // Convert seconds to milliseconds
                time = durationNumber * 1000;
            }
        }
    } else {
        if (typeof duration === 'string') {
            time = parseFloat(duration);
        }
    }

    return time;
};