import $ from 'jquery';
import { v1 as uuidv1 } from 'uuid';
import FormElement from './form-element'

/**
 * Forms related logic
 */
const Forms = (() => {

    /**
   * return form related logic impl.
   * 
   * TODO:
   * @urb max count of return items
   */
    const initReturnForm = () => {

        let $itemFieldgroupReplica, $form, $addBtn, itemsCount = 1, itemsMaxCount = 10;

        /**
         * create and prepare new item fieldgroup 
         * @param {*} $replica 
         * @returns 
         */
        let createFieldGroup = ($replica) => {
            // clone replica
            let $copy = $replica.clone();

            // set id (return-item-[timestamp])
            $copy.attr('id', `return-item-${uuidv1()}`);

            // set visibility
            $copy.removeClass('hide')
            // enable inputs
            $('input', $copy).each((idx, input) => {
                $(input).removeAttr('disabled');
            });

            return $copy;
        };

        /**
         * delete handler removes fieldGroup 
         * @param {*} $fieldGroup 
         */
        let removeFieldGroup = ($fieldGroup) => {
            $fieldGroup.remove();
            itemsCount--;

            // check for max items count
            if (itemsCount < itemsMaxCount) {
                $addBtn.removeAttr('disabled')
            }
        };


        $form = $('form#form-return');

        if ($form.length) {

            // get add item btn 
            $addBtn = $('#form-return-button-add', $form);

            $addBtn.on('click', function () {

                // check for max items count
                if (itemsCount < itemsMaxCount) {

                    // get item fieldgroup replica
                    if (!$itemFieldgroupReplica) {

                        $itemFieldgroupReplica = $('#return-item-fieldgroup-replica:hidden', $form);
                    }
                    // create  fieldgroup copy
                    let $itemFieldgroupCopy = createFieldGroup($itemFieldgroupReplica);

                    // bind handler to delete button
                    $('.button-delete-item', $itemFieldgroupCopy).on('click', removeFieldGroup.bind(null, $itemFieldgroupCopy));

                    // get last fieldgroup
                    let $lastFieldGroup = $(':not(#return-item-fieldgroup-replica).return-item-fieldgroup:last', $form);

                    // append copy after last fieldgroup
                    $itemFieldgroupCopy.insertAfter($lastFieldGroup);

                    itemsCount++;

                    // check max items
                    if (itemsCount == itemsMaxCount) {

                        // disable add button
                        $addBtn.attr('disabled', 'disabled')

                        // TODO: display some message?
                    }
                }

            });

            // initialize radios with dependent inputs
            FormElement.setupDependentElements($form);

            // alternative pick up address
            // enable/disable input fields
            $('details.pick-up-address', $form).on("toggle", (ev) => {
                if (ev.target.open) {
                    $('.form-item > input, .form-item > select', $(ev.target)).removeAttr('disabled')
                } else {
                    $('.form-item > input, .form-item > select', $(ev.target)).attr('disabled', 'disabled');
                }
            });

        };
    };

    const initCustomerSurveyForm = () => {

        const $form = $('form#form-customer-survey');

        if ($form.length) {
            FormElement.setupDependentElements($form);

            // TODO: @urb do we need that?
            // setup sticky header

            // get table
            //const $table = $('#edit-survey-questions-table');





        }



    };


    return {
        init: () => {
            initReturnForm(),
            initCustomerSurveyForm()
        }
    }

})();

export default Forms;