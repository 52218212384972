import $ from 'jquery';
import 'jquery-once';

/**
 *  stickySidebar.
 */
'use strict';

const StickySidebar = (function () {
  let $sidebar;

  const settings = {
    backToTop: {
      visibilityDelta: 900
    }
  };

  /**
   * Initialises sticky sidebar.
   *
   * @param {object} context
   *   Context.
   */
  const initialize = function () {
    $sidebar = $('.sticky-sidebar');


    $sidebar.once('sticky-sidebar').each(function () {


      // Add section toggle action on click.(except back to top element)
      $('.section-toggle:not(.no-toggle)', $sidebar).on('click', function () {

        // close opened ones
        $('.open', $sidebar).removeClass('open');

        // open the clicked one
        $(this)
          .addClass('open')
          .next()
          .addClass('open');

      });

      // Avoid section close on section click.
      $sidebar.on('click', function (e) {
        e.stopPropagation();
      });

      // Show/hide 'Back to top' button on scroll.
      if ($('.back-to-top', $sidebar).length) {

        $(document).once('sticky-sidebar').on('scroll', function () {
          scroll();
        });
        scroll();
      }
    });

    // Close sidebar sections on body click.
    $('body').once('sticky-sidebar').on('click', function () {
      $('.open', $sidebar).removeClass('open');
    });

  };

  /**
   * The on scroll handler function for 'Back to top' button inside sticky sidebar.
   */
  const scroll = function () {
    let shouldVisible = window.scrollY > settings.backToTop.visibilityDelta;

    // Show 'Back to top' button when user scrolls down.
    (shouldVisible) ? $('.sticky-sidebar .back-to-top').removeClass('hidden-visual')
      : $(' .back-to-top', $sidebar).addClass('hidden-visual');
  }

  return {
    init: initialize
  }

})($);

export default StickySidebar;


