import $ from 'jquery';
import 'jquery-once';
import VideoUtils from './video-utils';
/**
 * text_media module
 * reveal close handling regarding videos
 * table wrapper generation
 * 
 */
const TextMedia = (() => {

    const PARAGRAPH_SELECTOR = '.paragraph-text-media';
    const LIGHTBOX_SELECTOR = '.paragraph-text-media__lightbox';

    const initialize = () => {

        // stop videos on closing the lightbox
        $(LIGHTBOX_SELECTOR).once('lightBoxOnClose').each(function () {

            $(this).on('closed.zf.reveal', function () {
                // stop video in lightbox
                $('iframe', $(this)).each(function () {
                    VideoUtils.stopIframeVideo($(this));
                });
            });
        });

        enhanceTables();
    };

    /**
     * wraps tables with table-wrapper container if not already wrapped.
     */
    const enhanceTables = () => {

        // create wrapper element
        const $tableWrapper = $('<div class="table-wrapper"></div>');

        $(`${PARAGRAPH_SELECTOR} table`).once('wrapTables').each(function () {
            // check if table is missing wrapper as direct parent
            if($(this).parent('.table-wrapper').length === 0) {
                // wrap table
                $(this).wrap($tableWrapper);
            }
        })
    };


    return {
        init: initialize
    }
})();


export default TextMedia;