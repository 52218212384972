import API from "./api";
import ResultsPagination from "./results-pagination";
import ResultsSorter from "./results-sorter";
import { decodeUnicode } from "./utils";

/**
 * SearchResult 
 * 
 * implements generic pagination and sort functionality of result elements
 */
const SearchResult = (function () {

    // return function instead of block to allow instantiation of multiple instances
    return function (container) {

        // element to enhance with result functions
        this.container = container;

        if (!this.container) {
            throw new Error('SearchResult: container property required');
        }

        /**
         * initialize pagination elements within the container
         * 
         * @param {Function} handlePaginate callback function executed on paginate event.
         */
        this.initializePagination = (handlePaginate) => {
            let pagination;
            try {
                pagination = new ResultsPagination(container, handlePaginate);
                pagination.initialize();
            } catch (error) {
                console.error(error);
            }
            return pagination;
        };

        /**
         * initialize sort elements within the container
         * @param {Function} handleSort sort handler 
         */
        this.initializeSorter = (handleSort) => {
            let sorter;
            try {
                sorter = new ResultsSorter(container, handleSort);
                sorter.initialize();
            } catch (error) {
                console.error(error);
            }

            return sorter;
        };

        // fetch json 
        this.pullContent = url => API.pull(url, null, { "method": "GET" }, "json");

        /**
         * decode content from json unicode
         * @param {*} data assuming json structure: { content: "xxxx"}
         * @returns 
         */
        this.prepareContent = data => decodeUnicode(data.content);

        // set containers new content
        this.updateContent = content => container.html(content);
    }
})();

export default SearchResult;