import $ from 'jquery';
const ProtectedDownloads = (() => {

    const MODAL_SELECTOR = "#protected-downloads-lightbox";
    let $container;

    const initialize = () => {

        $container = $(MODAL_SELECTOR);

        if ($container.length) {

            const $iframe = $('iframe', $container);
            

            $container.on('open.zf.reveal', () => {

                if ($iframe.length) {

                    $iframe.off('load');
                    $iframe.on('load', () => {

                        const $iframeElement = $iframe.contents().find('.layout-content');
                        $iframeElement.addClass('in-iframe');
                    })

                }



            })
        }




    }


    return {
        init: initialize
    }

})($);

export default ProtectedDownloads;