import $ from 'jquery';

/**
 * ResultsPagination class
 * 
 * @param {*} container 
 * @param {*} callback 
 */
const ResultsPagination = class {

    #paginatorSelector = "nav.pager .js-pager__items";
    #paginatorItemSelector = ".pager__item a";
    paginators; 

    constructor (container, callback) {
        this.container = container;
        this.callback = callback;
    };
    
    // setters
    setPaginatorSelector = (selector) => {
        this.#paginatorSelector = selector;
    };
    setPaginatorItemSelector = (selector) => {
        this.#paginatorItemSelector = selector;
    };

    /**
     * 
     */
    initialize = () => {

        if (this.container?.length) {

            this.container = this.container 
    
            this.paginators = this.container.find(this.#paginatorSelector);
    
            if (this.paginators.length) {
    
                // bind event handler on paginator anchor elements click event
                const $pages = this.paginators.find(this.#paginatorItemSelector);
                this.bindEvents($pages);
            }

        } else {
            throw new Error('ResultsPagination: container not defined');
        }

    };

    /**
     * bind click event and define handler
     * @param {*} pages anchor elements of pagination '.pager__item a
     */
    bindEvents = (pages) => {

        pages.on('click', this.handleEvent)
    };

    /**
     * click event handler
     * @param {*} event 
     */
    handleEvent = (event) => {
        event.preventDefault();

        let $page = $(event.currentTarget);
        //console.debug($page.attr('title'));

        if (this.callback instanceof Function) {
            // TODO: @til what info to be processed by callback. page.href = api url or api path defined somewhere and parse page info from page.href?
            this.callback($page.attr('href'));
        } else {
            throw new Error('ResultsPagination: no callback of type function');
        }

    };

};

export default ResultsPagination;