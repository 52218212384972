import $ from 'jquery';
import SearchResult from './search-result';
import MainSpinner from './spinner';


/**
 * General search 
 */
const GeneralSearch = (() => {

    // selectors
    const PARAGRAPH_SELECTOR = ".paragraph-general-search",
    CONTAINER_SELECTOR = ".results-container";

    // searchResult instance store
    let searchResults = new Map();

    const initialize = () => {

        try {
            // get all .result-container elements
            let $resultContainers = $(PARAGRAPH_SELECTOR).find(CONTAINER_SELECTOR);
    
            if ($resultContainers.length) {
    
                $resultContainers.each(function (idx) {
                    let $container = $(this);
                    // create generic searchResult instance for each container
                    const searchResult = new SearchResult($container);
                    // save to hashmap by index
                    searchResults.set(idx, searchResult);
                    
                    initializeResultContainer(searchResult, idx)
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * initialize single container
     * @param {*} searchResult 
     * @param {*} name key of searchResult instance in the map.
     */
    const initializeResultContainer = (searchResult, name) => {

        // init pagination only as general search results do not contain any sorting
        searchResult.initializePagination(handlePaginate(name));
    }

    /**
     * TODO: generic
     * function passed to pagination instance and is executed on clicking a pagination element.
     * @param {*} containerName name of container needed to retrieve a specific searchResult instance from hashmap 
     */
    const handlePaginate = (containerName) =>  async (path) => {
        // get searchResult instance for container name
        let searchResult = searchResults.get(containerName);

        try {
            MainSpinner.toggle();
            const content = await searchResult.pullContent(path);
            const prepared = searchResult.prepareContent(content);
            searchResult.updateContent(prepared);
            initializeResultContainer(searchResult, containerName);
        } catch (err) {
            console.log(err);
        } finally {
            MainSpinner.toggle()
        }
    };

    return {
        init: initialize,
    }
})();

export default GeneralSearch;