import $ from 'jquery';


/**
 * Product options select elements
 * 
 * add class .product-options-container to the container holding the select elements
 * 
 * 
 * 
 * TODO:
 * - single selection value case handling?
 * 
 * 
 * 
 */
const ProductHS = (() => {
    const CONTAINER_SELECTOR = ".product-options-container",
    PRODUCT_OPTIONS_DATA_SELECTOR = "#product-options-data";

    let data;

    let $container,
        $dataContainer;

    const initialize = () => {

        $container = $(CONTAINER_SELECTOR);

        if ($container.length) {

            
            try {
                
                data = getData(PRODUCT_OPTIONS_DATA_SELECTOR);
                
                // create options
                const selectData = createOptionsData(data['options']);
                

                $container.each((idx, el) => {

                    let $groupContainer = $('select[data-hierarchy-level=1]', $(el));
                    // populate groups select with options
                    populateSelect($groupContainer, selectData)
    
                    // bind event handler
                    bindEventHandler($groupContainer);
    
                    performPreselect($groupContainer);
                })

            } catch (error) {
                console.error(error)
            }
        }

    };

    /**
     * set selection for the select element defined by the data attribute data-selected-id
     * @param {*} $select 
     */
    const performPreselect = ($select) => {

        const selectedId = $select.data('selected-id');
        if (selectedId) {

            $select.val(selectedId).trigger({
                type: 'select2:select',
                params: {
                    data: {
                        id: selectedId
                    }
                }
            })
            
            $select.trigger('change'); // need to trigger change event to for display change in selection
        }

    }

    /**
     * 
     * resets select element and initializes it with data
     * 
     * @param {*} element select2 element
     * @param {*} data select2 formatted data [{id:1, text: 'option 1'},...]
     */
    const populateSelect = (element, data) => {
        element.select2('destroy').empty()
            .select2({ data: data });
    };

    /**
     * bind/unbind event handler
     * @param {*} $select  select2 element
     */
    const bindEventHandler = ($select) => {

        // unbind events first
        $select.off("select2:select");
        $select.off("select2:unselect");
        $select.off("select2:clear");


        // bind events
        $select.on("select2:select", handleSelect)
        $select.on("select2:clear", handleUnselect)

    };

    /**
     * handler function for select event
     * @param {*} e 
     */
    const handleSelect = (e) => {

        // get curren select element
        const $current = $(e.currentTarget);
        const $currentContainer = $current.closest(CONTAINER_SELECTOR);

        // reset dependent selects
        resetDependants($current);

        // current elements level
        let level = $current.data('hierarchy-level');

        const item = findNestedObjectByPropertyValue(data, 'id', e.params.data.id);

        // populate next select element
        const $nextSelect = $(`select[data-hierarchy-level=${++level}]`, $currentContainer);

        if ($nextSelect.length) {

            if (item['options']) {

                const nextData = createOptionsData(item['options']);

                populateSelect($nextSelect, nextData);

                // enable disabled element
                $nextSelect.prop('disabled', false);
                bindEventHandler($nextSelect);

                performPreselect($nextSelect);
            }
        }

    };

    /**
     * handler for unselect/clear event
     * @param {*} e 
     */
    const handleUnselect = (e) => {
        const $current = $(e.currentTarget);
        resetDependants($current);
    };

    /**
     * resets current elements dependent selects
     * @param {*} $select current select element 
     */
    const resetDependants = ($select) => {
        // curren elements level
        let selLevel = parseInt($select.data('hierarchy-level'));
        const $currentContainer = $select.closest(CONTAINER_SELECTOR);

        // get select element candidates to be disabled.
        const $candidates = $(`select[data-hierarchy-level]`, $currentContainer).filter((idx, el) => {
            const elLevel = parseInt($(el).data('hierarchy-level'));
            return elLevel > selLevel;
        });

        // empty and disable selects with higher levels
        $candidates.val(null).trigger('change');
        $candidates.empty();
        $candidates.prop('disabled', 'true')
    };


    const getData = (dataContainerSelector) => {

        $dataContainer = $(dataContainerSelector);

        if ($dataContainer.length) {

            return JSON.parse($dataContainer.text());
        } else {
            throw new Error('no data container defined for product options')
        }
    }


    /**
     * utility function to get object with by property key and value
     * @param {*} obj 
     * @param {*} targetKey 
     * @param {*} targetValue 
     * @returns 
     */
    function findNestedObjectByPropertyValue(obj, targetKey, targetValue) {
        if (!obj || typeof obj !== 'object') {
            return undefined;
        }

        let queue = [obj];

        while (queue.length > 0) {
            let current = queue.shift();

            for (let key in current) {
                if (current.hasOwnProperty(key)) {
                    if (key === targetKey && current[key] == targetValue) {
                        return current;
                    }
                    if (typeof current[key] === 'object' && current[key] !== null) {
                        queue.push(current[key]);
                    }
                }
            }
        }

        return undefined;
    }


    /**
     * create options data for select2 element
     * 
     * if only single entry in option, set it selected
     * 
     * @param {*} options 
     * @returns 
     */
    const createOptionsData = (options) => {

        const selectOptions = Object.entries(options).map((it) => {
            return {
                id: it[1].id,
                text: it[1].name,
            }
        });

        // add empty option at first place
        selectOptions.unshift({
            id: "",
            text: ""
        })

        return selectOptions;
    };

    return {
        init: initialize
    };

})();

export default ProductHS;