import $ from 'jquery';
import '../../vendor/slick/slick'; 

/**
 * hero slider related functions
 * 
 * slider wrapper element needs class 'paragraph--type--rotating-banner' set to get initialized as a slider
 * slide elements need class set defined by the activeSlideClass variable. 
 * initialization options may be passed by using data-slick attribute in the markup.
 * 
 * more info about the slick component: https://kenwheeler.github.io/slick/
 * 
 */
const RotatingBanner = (() => {
    // slider query selector
    const sliderWrapperClass = '.paragraph--type--rotating-banner';
    // slide query selector to display in slider
    const activeSlideClass = '.paragraph';

    let options;
    let defaultOptions = {
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: activeSlideClass,
        autoplay: true,
        autoplaySpeed: 5000
    }

    /**
     * initialize slider
     */
    const initialize = () => {
            
        // get wrapper element
        let $sliderWrapper = $(sliderWrapperClass);

        // initialize with slick
        if ($sliderWrapper.length) {
            $sliderWrapper.once('slickSlider').each(function () {

                
                let slideCount = $sliderWrapper.find(activeSlideClass).length;
                
                // initialize slider wrapper has > 1 elements with activeSlideClass to show if
                if (slideCount) {
                    // update default options
                    defaultOptions.dots = slideCount > 1;

                    // get options per data attribute
                    let dataOptions = $(this).data('slick');
                    // check if options set by data attribute data-slick
                    if(dataOptions) {
                        // merge options
                        options = {...defaultOptions, ...dataOptions}
                    } 
                    else {
                       options = defaultOptions;

                    }

                    // init slider 
                    $(this).slick(options);
                    // NOTE: if filtering needed: 
                    //.slick('slickFilter', activeSlideClass);
                }
            });
        }
    };

    return {
        init: initialize
    }

})($)

export default RotatingBanner;