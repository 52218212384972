import $ from 'jquery';
import 'jquery-once'

/**
 * Main menu related logic
 * 
 */
const MainMenu = (() => {

    $.extend({
        replaceTag: function (element, tagName, withDataAndEvents, deepWithDataAndEvents) {
          var newTag = $("<" + tagName + ">")[0];
          // From [Stackoverflow: Copy all Attributes](http://stackoverflow.com/a/6753486/2096729)
          $.each(element.attributes, function () {
            newTag.setAttribute(this.name, this.value);
          });
          $(element).contents().clone(withDataAndEvents, deepWithDataAndEvents).appendTo(newTag);
          return newTag;
        }
      })
      $.fn.extend({
        replaceTag: function (tagName, withDataAndEvents, deepWithDataAndEvents) {
          // Use map to reconstruct the selector with newly created elements
          return this.map(function () {
            return jQuery.replaceTag(this, tagName, withDataAndEvents, deepWithDataAndEvents);
          })
        }
      })

    const initialize = () => {

        var $mainMenuNav = $('nav.main-navigation');
    
        var levelOneMenu = [];
    
        function resetMenu() {
          var dd = $('.dropdown-pane');
          $('.dropdown-menu-container', dd).removeAttr('style');
          $('.main-menu--sub-1', dd).removeAttr('style');
          $('li', dd).removeClass('submenu-active');
          $('.back-link', dd).addClass('hidden');
        }
    
        $('.main-menu-desktop > .main-menu__item--with-sub > a').on('click', function (e) {
          e.preventDefault();
          resetMenu();
        });
    
        $('.dropdown-pane', $mainMenuNav).once('showDD').each(function (i) {
          $(this).on('show.zf.dropdown', function () {
            var levelOne = levelOneMenu[i] = $('.main-menu--sub-1', this);
            var menuContainer = levelOne.parent();
            var menuWidth = $(levelOne).width();
            var backLink = $('.back-link', this);
            levelOne.menuPos = 0;
            levelOne.visibleLevel = 3;
    
            // Click on submenu item
            $('.main-menu__item--with-sub > a', this).on('click', function (e) {
              levelOne.itemLevel = parseInt($(this).attr('data-level').slice(6));
              var menuContainerHeight = menuContainer.height();
              var subMenuHeight = $(this).next('ul').height();
    
              // Position the menu
              // Visible level is 4+
              if (levelOne.itemLevel > 2) {
                levelOne.menuPos = -menuWidth * (levelOne.itemLevel - 2) - 20; //  20 = padding
                levelOne.visibleLevel = levelOne.itemLevel + 1;
                $(backLink).removeClass('hidden');
              }
              else {
                levelOne.menuPos = 0;
                levelOne.visibleLevel = 3;
              }
    
              $(levelOne).css('left', levelOne.menuPos);
    
              // Hide back link on levels 1-3
              if (levelOne.visibleLevel <= 3) {
                $(backLink).addClass('hidden');
              }
    
              // Set dropdown height to be able to show all (absolutely positioned) sub menu items
              if (subMenuHeight > menuContainerHeight) {
                menuContainer.css('min-height', subMenuHeight);
              }
    
              // main-menu__item--active is in active trail
              $(this).parent().addClass('submenu-active').siblings().removeClass('submenu-active');
              e.preventDefault();
            });
    
            // Click on Back link - go back one level
            $(backLink).on('click', function () {
              if (levelOne.visibleLevel <= 4) {
                levelOne.menuPos = 0;
                levelOne.visibleLevel = 3;
              }
              else {
                levelOne.menuPos = parseFloat($(levelOne).css('left')) + menuWidth;
                levelOne.visibleLevel = levelOne.visibleLevel - 1;
              }
    
              // Hide Back link for first 3 levels
              if (levelOne.visibleLevel <= 3) {
                $(this).addClass('hidden');
              }
    
              $(levelOne).css('left', levelOne.menuPos);
            });
    
            // Add titles as links to menu columns
            // Get main menu link
            $('.main-menu--sub-1').once().each(function () {
              if ($(this).parents('.dropdown-pane').prev('a').hasClass('no-target')) {
                $(this).parents('.dropdown-pane').prev('a').clone().replaceTag('h4').prependTo($(this)).wrap('<li class="ul-title"></li>');
              }
              else {
                $(this).parents('.dropdown-pane').prev('a').clone().prependTo($(this)).wrap('<li class="ul-title"></li>');
              }
            });
    
            // Get level 1+ links
            $('.main-menu--sub:not(.main-menu--sub-1)').once().each(function () {
              if ($(this).prev('a').hasClass('no-target')) {
                $(this).prev('a').clone().replaceTag('h4').prependTo($(this)).wrap('<li class="ul-title"></li>');
              }
              else {
                $(this).prev('a').clone().prependTo($(this)).wrap('<li class="ul-title"></li>');
              }
            });
    
          });
        });
    
    
        $(window).on('resize', function () {
          var dd = $('.dropdown-pane.is-open');
          // Hide dropdown-pane
          dd.removeClass('is-open').attr({ 'aria-hidden': true });
          // Main menu level 0 item
          dd.prev().removeClass('hover').attr('aria-expanded', false);
    
          resetMenu();
        });


        mobileMenuFix();
    
      };
    
      /**
       * do some text replacement, css fixes for mobile menu 
       */
      const mobileMenuFix = () => {
    
        $('.main-menu-mobile .js-drilldown-back a').once('mobileMenuFix').each(function () {
          var backTxt = $(this).parent().closest('.is-drilldown-submenu-parent').find('> a').text();
          $(this).text(backTxt);
        });
    
        // prepend 'All' to parent textå
        // TODO check how to pass 'All'
        $('.main-menu-mobile .is-submenu-parent-item > a').once('mobileMenuFix').each(function () {
          $(this).text('[[All]]' + ' ' + $(this).text()); 
        });
    
        $('.main-menu-mobile').on('scroll', (function (e) {
          $(this).find('.main-menu-mobile--sub').css({ 'top': $(this).scrollTop() });
        }))
      };

      return {
        init: initialize
      }

})($);

export default MainMenu;