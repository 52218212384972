import $ from 'jquery';
import 'jquery-once';
import '../../vendor/select2/js/select2';


const SiteHeader = (() => {
  'use strict'; 

  const initialize = () => {

    // Language switcher select2 initialisation
    $('#lang_dropdown_form_lang-dropdown-form select').select2({
      minimumResultsForSearch: '-1',
      dropdownParent: $('.language-switcher')
    });

    // Sticky header 
    $('.js-header').once('stickyHeader').each(function () {
      //---------------------------------------------------------------------
      function stickyHeader() {
        // Global function parameters
        var sticky = {
          props: {
            target: {
              selector: '.js-header',
              state: {
                up: 'is-up',
                elevate: 'is-elevated'
              }
            },
            scrollPosition: {
              before: 0,
              after: 0
            }
          }
        };

        /*
         * headerHeight
         * Returns header height in pixels [number]
         */
        function headerHeight() {
          var target = sticky.props.target.selector;
          var height = $(target).height();
          var headerHeight = !isNaN(height) ? height : 0;

          return headerHeight;
        };


        /**
         * compensatePadding
         * adds/remove top padding to 'body' element to compensate for 'header' becoming
         * fixed.
         * @param {string} toggle ('set'/'unset')
         */
        const compensatePadding = (toggle) => {
          if (toggle === 'set') {
            $('body').css({
              'padding-top': headerHeight()
            });
          } else if (toggle === 'unset') {
            $('body').removeAttr('style');
          }
        };

        /**
         * scrollFromTop
         * @Returns {number} current scroll value from the top in pixels [number]
         */
        const scrollFromTop = () => $(window).scrollTop();

        /**
         * scrollDirection
         * Shows the scroll direction - up or down
         * Returns 1 for up and -1 for down
         * @param {number }scrollFromTop 
         */
        const scrollDirection = (scrollFromTop) => {
          sticky.props.scrollPosition.after = scrollFromTop;

          const before = sticky.props.scrollPosition.before
          const after = sticky.props.scrollPosition.after
          let direction = 0;

          if (after > before) {
            // Scroll direction 'down'
            direction = 1;
          } else {
            // Scroll direction 'up'
            direction = -1;
          }

          sticky.props.scrollPosition.before = scrollFromTop;

          return direction;
        };

        /** 
         * toggleSticky
         * makes the header sticky or non-sticky
         * @param {string} toggle ('slide-up'/'elevate'/'flatten'/'slide-down')
         */
        const toggleSticky = (toggle) => {
          const selector = sticky.props.target.selector;
          const up = sticky.props.target.state.up;
          const elevate = sticky.props.target.state.elevate;

          if (toggle === 'slide-up') {
            $(selector).addClass(up);

          } else if (toggle === 'elevate') {
            $(selector).addClass(elevate);

          } else if (toggle === 'flatten') {
            $(selector).removeClass(elevate);
            $('body').removeClass('anchor-sticky');

            const $anchorSwiper = $('.c-in-page-navigation');
            if ($anchorSwiper) {
              let $anchorSwiperLine = $anchorSwiper.find('.c-in-page-navigation__list-items__active-line');
              const $anchorSwiperLineWidth = $anchorSwiperLine.next('.swiper-slide').width();

              $anchorSwiperLine.css({
                'left': '0',
                'transform': 'translateX(0)',
                'width': $anchorSwiperLineWidth
              });
            }

          } else if (toggle === 'slide-down') {
            $(selector).removeClass(up);
          }
        };


        // On scroll event
        $(window).scroll(function (event) {
          const scrollPos = scrollFromTop() ? scrollFromTop() : 0;

          const direction = scrollDirection(
            scrollFromTop()
          );

          // Make the header sticky once the user scrolls past it
          if (scrollPos > 0) {
            toggleSticky('elevate');

            if (scrollPos > headerHeight()) {
              toggleSticky('slide-up');

              // // Show menu if user scrolls up, otherwise hide it
              if (direction < 0) {
                toggleSticky('slide-down');
              }
            }
          } else {
            toggleSticky('flatten');
          }
        });

        // On resize
        $(window).resize(function () {
          compensatePadding('set');
        });


        // init
        $(sticky.props.target.selector).addClass('is-fixed');
        compensatePadding('set');

      };

      // Init
      // On Product finder page don't include sticky header as it contradicts with Product finder sticky header.
      var $product_finder = $('#product-finder-container');
      if (!$product_finder.length) {
        stickyHeader();
      }
      //---------------------------------------------------------------------
    });

    /* Close the main menu navigation dropdown when clicking on the close button */
    var mainMenuCloseBtn = $('.main-menu--close-btn').once('main-menu--close-button');
    var mainMenuLink = $('.main-menu__link');

    mainMenuCloseBtn.on('click', function () {
      if ($('.dropdown-pane:visible').length) {
        $('.dropdown-pane').removeClass('is-open');
        mainMenuLink.removeClass('hover');
      } else {
        $('.dropdown-pane').addClass('is-open');
        mainMenuLink.addClass('hover');
      }
    })
  };

  return {
    init: initialize
  };
})($);

export default SiteHeader;
