import $ from 'jquery';
import API from './api';
import MainSpinner from './spinner';
import SearchResult from './search-result';

/**
 * File search results related functions
 * 
 * - check/uncheck all for download
 * - pagination: bind event 
 * - replace results content
 * 
 * TODOs:
 * - sort: bind event to retrieve new data from endpoint
 * - handle download
 * 
 * 
 */
const FileSearchResult = (() => {

    // selectors
    const CONTAINER_SELECTOR = "#file-search-content";
    const RESULTS_FORM_SELECTOR = "form"
    // elements
    let $container, $resultsForm, $selectAllCheckbox, $submitBtn;

    // api related variables
    let endpoint, method;

    // pagination and sorter instance
    let pagination, sorter;

    // searchResult instance
    let searchResult;


    const initialize = () => {

        $container = $container || $(CONTAINER_SELECTOR);

        if ($container.length) {

            $resultsForm = $(RESULTS_FORM_SELECTOR, $container),
                $selectAllCheckbox = $('th.select-all input', $container),
                endpoint = $container.data('endpoint'),
                method = $container.data('method');

            initializeCheckboxes();
            initializeSelectAll();
            
            
            //initializeDownload();

            // instantiate generic searchResult object to handle common tasks
            searchResult = new SearchResult($container);
            pagination = searchResult.initializePagination(handleAction);
            sorter = searchResult.initializeSorter(handleAction);
        }
    };

    /**
     * 
     * initialize Download button
     * 
     */
    const initializeCheckboxes = () => {

        $submitBtn = $resultsForm.find('input[type="submit"]');
        $submitBtn.attr('disabled', true);

        $('input.form-checkbox', $resultsForm).not('.select-all input').each((_, it) => {
            $(it).on('change', handleSelectForDownload)
        });
    };

    /**
     * checkbox handler for each input on selection.
     * @param {*} e 
     */
    const handleSelectForDownload = (e) => {
        // get no. of checked inputs
        const count = $('input.form-checkbox', $resultsForm)
            .not('.select-all input')
            .filter((_, el) => el.checked)
            .length;

        if (count > 0) {
            $submitBtn.attr('disabled', false);
        } else {
            $submitBtn.attr('disabled', true);
            $selectAllCheckbox.prop('checked', false);
        }
    };

    /**
     * initialize select-all checkbox
     */
    const initializeSelectAll = () => $selectAllCheckbox.on('click', selectAllHandler);
    
    /**
     * handle select/unselect for all-select checkbox
     */
    const selectAllHandler = () => {
        let isChecked = false;

        if ($selectAllCheckbox.prop('checked')) {
            isChecked = true;
        }

        $('input.form-checkbox', $resultsForm).each((_, it) => {
            $(it).prop('checked', isChecked).trigger('change');
        });
    };

    /**
     * 
     * callback function passed to pagination/sorter instance 
     * @param {*} param 
     */
    const handleAction = async (path) => {

        try {
            MainSpinner.toggle()
            const content = await searchResult.pullContent(path);
            const prepared = searchResult.prepareContent(content);
            searchResult.updateContent(prepared);
            initialize();
        } catch (err) {
            console.log(err);
        } finally {
            MainSpinner.toggle()
        }
    };

    /**
     * binds handler to form submit event.
     * @deprecated form has target set _blank
     */
    const initializeDownload = () => {

        $resultsForm.on('submit', handleDownload);
    };

    /**
     * handler function for clicking on download btn
     * 
     * - response header
     *  content-type: 
     *  content-disposition 'attachment; filename="download.zip'
     * ....
     * @deprecated
     */
    const handleDownload = async (e) => {
        e.preventDefault();

        // get selected values 
        const data = $resultsForm.serializeArray();
        pullFiles(data);
    };

    /**
     * 
     * fetch files defined 
     * 
     * @param {*} files array of objects [{name: 'input_name', value: 'input_value'},{{name: 'input_name', value: 'input_value'}...]
     */
    const pullFiles = (files) => {

        // TODO: @til service path elsewhere
        const url = $resultsForm.prop("action");
        const method = $resultsForm.prop("method");

        // prepare serialized data
        // this way fetch api can handle it easily
        const params = jQuery.param(files);

        const requestOptions = {
            method: method,
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        // fetch document
        API.pull(url, params, requestOptions, "blob")
            .then((res) => {

                const href = URL.createObjectURL(res);
                window.open(href);
                URL.revokeObjectURL(href);

            }).catch((err) => {
                console.error(err);
            });
    };

    return {
        init: initialize
    }

})();

export default FileSearchResult;
