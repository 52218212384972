import $ from 'jquery';
import 'jquery-once';

/**
 * Common
 * 
 * handle common stuff
 */
const Common = (() => {


  $.extend({
    replaceTag: function (element, tagName, withDataAndEvents, deepWithDataAndEvents) {
      var newTag = $("<" + tagName + ">")[0];
      // From [Stackoverflow: Copy all Attributes](http://stackoverflow.com/a/6753486/2096729)
      $.each(element.attributes, function () {
        newTag.setAttribute(this.name, this.value);
      });
      $(element).contents().clone(withDataAndEvents, deepWithDataAndEvents).appendTo(newTag);
      return newTag;
    }
  })
  $.fn.extend({
    replaceTag: function (tagName, withDataAndEvents, deepWithDataAndEvents) {
      // Use map to reconstruct the selector with newly created elements
      return this.map(function () {
        return jQuery.replaceTag(this, tagName, withDataAndEvents, deepWithDataAndEvents);
      })
    }
  })


  const initButtons = () => {
    const shake = {
      classNames: 'shake animated'
    }

    // 'pointerup' event was used since 'clicked' or 'mouseup' do not work
    // when the button has a 'disabled' attribute
    $('.button[disabled], .button.disabled').on('pointerup', function () {
      const $this = $(this);

      // Stat the animation only if the there is no animation running currently
      if (!$this.hasClass(shake.classNames)) { // addClass doesn't have a callback function
        $this.addClass(shake.classNames);
        const computedStyles = window.getComputedStyle($this[0]);
        const animationDuration = computedStyles.animationDuration ? computedStyles.animationDuration : '1s';

        // Get the animation duration in milliseconds
        const durationMilliseconds = timeToMilliseconds(animationDuration);

        // Remove the animation classes only after the animations has finished
        setTimeout(function () {
          $this.removeClass(shake.classNames);
        },
          durationMilliseconds
        );
      }
    });
  };

  return {
    init: () => {
      initButtons();
    }
  }

})($);

export default Common;