
import $ from 'jquery';

const getElement = selector => {
    return $(selector)
};
const toggle = () => {
    MainSpinner.element.toggle();
}


/**
 * Main Spinner module
 */
const MainSpinner = {
    selector: null,
    element: null,
    toggle: toggle,

    initialize: (selector) => {
        MainSpinner.selector = selector;
        MainSpinner.element = getElement(selector);

        return MainSpinner;
    }
}

export default MainSpinner;






