import { Loader } from '@googlemaps/js-api-loader';

const LocationMap = ((Loader) => {

    const MARKER_ICON_URL = "/assets/img/pins/marker_pan_distributors.svg"
    const MAP_CONTAINER_ID = "map-container";
    const CUSTOM_CONTROLS_CONTAINER_ID = "map-controls-container"
    const LOCATE_BTN_ID = "locate-user";
    let options, loader, mapContainer, controlsContainer, locateBtn, map, marker, key;

    const defaults = {
        center: {
            lat: 50,
            lng: 22
        },
        zoom: 10,
        mapTypeControl: true,
        

        streetViewControl: false,

    };

    const initialize = () => {

        mapContainer = document.getElementById(MAP_CONTAINER_ID);
        locateBtn = document.getElementById(LOCATE_BTN_ID);
        controlsContainer = document.getElementById(CUSTOM_CONTROLS_CONTAINER_ID);

        if (mapContainer) {

            options = updateOptions();

            key = mapContainer.dataset.apikey;

            if (key?.length) {

                loader = new Loader({
                    apiKey: key,
                    version: "weekly",
                    libraries: []
                });

                loadMap();
                locateBtn.addEventListener('click', getUsersLocation);
            } else {
                console.error('no apikey for maps defined');
            }


        }
    };

    const updateOptions = () => {

        const dataOptionsStr = mapContainer?.dataset.options


        if (dataOptionsStr) {
            const dataOptions = JSON.parse(dataOptionsStr);

            return { ...defaults, ...dataOptions }
        } else {
            console.warn('no options for map defined')
        }

        return defaults;
    }

    const loadMap = () => {

        loader.importLibrary('maps').then(
            () => {

                // set controls
                options.mapTypeControlOptions = {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.TOP_CENTER,
                };


                map = new google.maps.Map(mapContainer, options);
                
                // display marker
                marker = new google.maps.Marker({
                    position: options.center,
                    map,
                    title: "",
                    icon: MARKER_ICON_URL
                });

                // add custom controls
                map.controls[google.maps.ControlPosition[controlsContainer.dataset.googleMapControlPosition]].push(controlsContainer);


            }
        ).catch((e) => {
            console.error(e)
        });

    };

    const updatePosition = (position) => {

        map.setCenter(position);
    };

    /**
             * Get users location with the Geolocation API
             * only supported by html5 browsers
             * TODO: handle case for old browsers
             * @returns {undefined}
             */
    const getUsersLocation = () => {

        // checking support for geolocation feature (only for html5)
        if (navigator.geolocation) {

            //GET USER CURRENT LOCATION
            navigator.geolocation.getCurrentPosition(
                // success handler
                function (position) {
                    console.debug('position: ' + position);

                    // create google.LatLng object 
                    const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

                    updatePosition(latLng);


                },
                // error handler
                function () {
                    // do nothing when user does not allow localization.
                    // disable localize button
                    locateBtn.setAttribute('disabled', 'disabled');

                }
            );
        } else {
            // TODO: handle browsers with no geolocation support
            console.debug('browser does not support geolocation');
            // define FALLBACK here
        };

    }


    return {
        init: initialize
    };

})(Loader);

export default LocationMap;