import $ from 'jquery';
import 'jquery-once';

/**
 * Utility class for videos
 * 
 * 
 * 
 */
const VideoUtils = (function () {
  'use strict';

  const mediaContainerSelector = '.video.section-video--wrapper';

  /**
   * handling functionality when using poster for iframe videos.
   */
  const setupVideoPoster = () => {
    let $mediaContainer = $(mediaContainerSelector),
      evtType = window.touch ? 'touchstart' : 'click';

    $mediaContainer.once('mediaPoster').each(function () {

      let $current = $(this);

      $(this).find('.section-video--btn').on(evtType, function () {

        let $iframe = $current.find('iframe')

        if ($iframe.length) {

          try {
            playIframeVideo($iframe);
          } catch (error) {
            console.log(error)
          } finally {

            //$iframe[0].src += "?autoplay=1"; // TODO check src url if any query params exist
            $(this).hide();
            $current.find('.section-video--poster').hide();
          }


        }
      })
    });
  };

  /**
   * starts iframe embeded youtube video
   * @param {*} $iframe 
   */
  const playIframeVideo = ($iframe) => {
    if (URL.canParse($iframe[0].src)) {

      let videoURL = new URL($iframe[0].src);
      let searchParams = videoURL.searchParams;

      searchParams.set('autoplay', 1);
      $iframe[0].src = videoURL.href;


    } else {
      throw new Error('video url not valid');
    }
  };
  /**
   * stops iframe embedded video
   * @param {*} $iframe 
   */
  const stopIframeVideo = ($iframe) => { 

    if (URL.canParse($iframe[0].src)) {

      let videoURL = new URL($iframe[0].src);
      let searchParams = videoURL.searchParams;

      searchParams.set('autoplay', 0);
      $iframe[0].src = videoURL.href;


    } else {
      throw new Error('video url not valid');
    }
  };

  return {
    init: () => {
      setupVideoPoster();
    },
    stopIframeVideo: stopIframeVideo
  }

})($);

export default VideoUtils;
