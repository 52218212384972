import $ from 'jquery';
import '../../vendor/select2/js/select2';
import FormElement from './form-element';
import API from './api';

/**
 * 
 * 
 * 
 * 
 * TODOS:
 * 
 * * populate data by url params on page reload (search executed)
 * x set selected elements
 * x update filters on change
 * x ensure that higher lever selects are resetted on selection.
 * x multiple select.
 * x preselec value if single entry
 * 
 * - special cases: 
 *   Amorton: no category
 * 
 * !!! multiselect name with [] filters update etc not working properly
 * 
 * @til:
 * - multiselect names [] => categories endpoint not working correctly. form submit params?
 * - empty values in response null vs [{'',''}]
 * 
 */

const FileSearchFilter = (() => {

    // selectors
    const FILTERS_FORM_SELECTOR = "#file-search-filters-form",
        FILTERS_SELECTOR = "#file-search-filters",
        FILTERS_DATA_SELECTOR = "#file-search-filters-data";

    // variables for jquery objects
    let $form, $shsContainer, $dataContainer, $productCategoriesContainer, $fileCategoriesContainer;

    // api endpoint for file categories
    let endpointCategories;
    let methodCategories;

    // filter values
    // hashmap storing key: select name / value: select value 
    let params = null;

    // store name attribute value of the current select
    let currentSelectName;

    const initialize = async () => {

        $form = $(FILTERS_FORM_SELECTOR);
        $shsContainer = $(FILTERS_SELECTOR, $form);

        if ($shsContainer.length) {

            // element holding inital data and info about endpoint url & method
            $dataContainer = $(FILTERS_DATA_SELECTOR);

            // get data attribute values
            endpointCategories = $shsContainer.data('endpoint-url');
            methodCategories = $shsContainer.data('endpoint-method'); // default is GET
            if (!endpointCategories) throw new Error('file-search-categories: no endpoint defined');


            let data = $dataContainer.text();

            // get search params from current location
            params = new URLSearchParams(window.location.search);
            console.debug(Array.from(params.entries()));

            // populate select values
            if (data) {
                await setupFields();
                updateFields(JSON.parse(data));
            } else {
                throw new Error('file-search: no initial data');
            }
        }
    };


    /**
     * initialize selects with select2 plugin
     * 
     * @param {*} json 
     */
    const setupFields = async () => {

        // select2 init options
        let options = {
            minimumResultsForSearch: '10', // no. of options needed for an active search input.

        };

        // get selects of container
        $('.form-select-shs', $shsContainer).each((idx, el) => {
            const $select = $(el);

            // check if field already initialized with select2 plugin
            if (!$select.hasClass("select2-hidden-accessible")) {

                options.placeholder = $select.siblings('label').text().trim()

                if ($select.prop('multiple')) {
                    options.templateResult = FormElement.multipleSelectformatState;
                    options.closeOnSelect = false;
                }

                $select.select2(options);
                bindEvents($select);
            }
        });
    };


    /**
     * update fields with new data
     * 
     * @param {*} json 
     */
    const updateFields = (json) => {

        // iterate over data keys and find corresponding field to populate each
        Object.keys(json).forEach((k, i) => {

            // avoid rebuilding current select 
            // TODO: same for its precedessors
            if (currentSelectName?.replace('[]','') !== k) {

                // get select field for data key
                let $select = $(`[name^="${k}"].form-select-shs`, $shsContainer);

                if ($select.length) {

                    let data = prepareData(json[k]);

                    populate($select, data).then(
                        select => {
                            // get selected values
                            //let selectedValues = getCurrentSelectionValues(select);
                            let selectedValues = getParamsByElement(select);
                            // set options selected 
                            selectOptions(select, selectedValues, data);
                        }
                    ).catch(err => {
                        console.error(err);

                    });

                    // set visibility regarding the data content
                    // only initialize elements with existing data
                    // exclude selects with single {'':''} entry
                    // TODO @til null im response möglich?
                    if (data.length && !(data.length == 1 && data[0].id == "")) {

                        $select.parent().show();
                    } else {

                        $select.parent().hide();
                        // TODO delete corresponding filters entry
                    }
                }
            }
        });
    };

    /**
     * get all select element successors of the select element within the category container
     * 
     * @param {*} $element 
     * @returns 
     */
    const getSuccessors = ($element) => $element.parent().nextAll().find('select');
    

    /**
     * get array of names from an array of select objects
     * @param {*} elements 
     * @returns 
     */
    const getElementNames = (elements) => elements.map((idx, el) => $(el).attr('name'));

    const getSuccessorsNamesToDelete = ($element) => {
        let names = [];

        // successor elements
        const successors = getSuccessors($element);
        const successorNames = getElementNames(successors);
        names.push(...successorNames);

        return names;
    }

    /**
     * params updates:
     * - elements successors filters
     * - filter entries with empty values
     * 
     * @param {JQuery} $element 
     */
    const updateParams = ($element) => {
        deleteSuccessorsParams($element);
        deleteEmptyParams();
    };

    const deleteSuccessorsParams = ($element) => {
        const successorParams = getSuccessorsNamesToDelete($element);
        if (successorParams.length) {
            deleteParams(successorParams);
        }
    };

    /**
     * delete params entries
     * @param {Array} entries array of filter names to delete
     */
    const deleteParams = (entries) => {

        entries.forEach((it) => {
            params.delete(it)
        });
    };

    /**
     * delete filter entries with empty value
     */
    const deleteEmptyParams = () => {

        // get filter items with empty values
        const items = [...params.entries()].filter((it) => {
            return it[1] === null || !it[1].length;
        });

        // store names in array
        const names = items.flatMap((it) => {
            return it[0];
        });

        deleteParams(names);
    };

    /**
     * get selected value of select element stored in the filters map
     * 
     * @param {*} $select select element
     * @returns 
     */
    //const getCurrentSelectionValues = ($select) => filters.get($select.attr('name'));
    
    /**
     * 
     * @param {*} $element elements name attribute used to search for values
     * @returns array of values 
     */
    const getParamsByElement = ($element) => {
        
        let values = params.getAll($element.attr("name"));

        return values;
    };


    /**
     * populate select element with options
     * 
     * @param {*} $select 
     * @param {*} data json 
     * @returns populated select element
     */
    const populate = async ($select, data) => {

        $select.val(null).trigger('change');
        // clear options first
        $select.empty();

        data.forEach((it) => {

            let option = new Option(it.text, it.id, false, false)

            $select.append(option);
        });

        return $select;
    };

    /**
     * set selected option(s) of the select element
     * if select has no values to be selected, remove filters for this element.
     * select with single option is automatically set as selected. NOTE: this does not trigger the select event so no api call is performed.
     * 
     * 
     * @param {*} $select select element
     * @param {*} values filter values related to select element
     */
    const selectOptions = ($select, values, data) => {

        // select option when filter values exists
        if (values?.length) {

            // check if select has an option with the values given
            const $options = $('option', $select);
            if (hasValues($options, values)) {
                $select.val(values).trigger('change');
            } else {
                // delete filters for select
                console.debug('no values to select');
                // TODO: should check each value and update filter values instead of deleting the whole filters entry
                deleteParams([$select.attr('name')])
                
            }
        } else {
            // otherwise check if element has only one option
            // so set it selected eg. [{"",""},{"Amorton", "Amorton"}]
            if (data?.length === 2) {
                $select.val(data[1].id).trigger('change');
            }
        }

    };

    /**
     * utility function to check if value exists in jquery options 
     * 
     * @param {*} $options 
     * @param {*} targetValue 
     * @returns 
     */
    const hasValues = ($options, targetValues) => {

        // handle single value
        if (!Array.isArray(targetValues)) {
            targetValues = [targetValues];
        }

        var hasAllTargets = true;
        targetValues.forEach((targetValue) => {
            var hasTarget = false;
            $options.each(function () {
                if ($(this).val() === targetValue) {
                    hasTarget = true;
                    return false; // Exit the loop early if the target value is found
                }
            });
            if (!hasTarget) {
                hasAllTargets = false;
                return false; // Exit the loop early if any target value is not found
            }
        });
        return hasAllTargets;
    }

    /**
     * convert received data to specific select2 data structure
     * 
     * [{
     *    id: ...,
     *    text: ...
     *  },...]
     * 
     * @param {*} json response data
     * @returns select2 conform data
     */
    const prepareData = (json) => {
        let data = []

        if (json) {

            data = Object.keys(json)
                //.filter(k => k !== "") // remove entries with empty string keys
                .map((k) => {
                    return {
                        "id": k,
                        "text": json[k]
                    }
                });
        }

        return data;
    };

    /**
     * bind handler funcitons to specific events
     * 
     * @param {*} $select2 
     */
    const bindEvents = ($select2) => {

        $select2.on("select2:select", handleSelect);
        $select2.on("select2:unselect", handleSelect);
    };

    const unbindEvents = ($select2) => {
        $select.off("select2:select");
        $select.off("select2:unselect");
    }

    /**
     * handler for events:
     *  - select
     *  - unselect
     * 
     * @param {*} event 
     */
    const handleSelect = async (event) => {
        console.debug(event.type);

        let $el = $(event.currentTarget)
        // save name as currentSelect
        currentSelectName = $el.attr('name');

        // selection
        let values = $el.val();
        console.debug(`current values ${currentSelectName}: ${values}`);

        // new selection
        //const selectedOption = event.params.data;
        //console.debug(`current selection: ${selectedOption.id}`);

        // set params
        params.set(currentSelectName, values);

        // this code below was a workaround as in some cases values was returning only the current selection. REASON: multiselect name with []!!!
        // handle multiple selects array values
        /* if (Array.isArray(filters.get(name))) {

            // different handling regarding type of event
            if (event.type === 'select2:select') {
                filters.set(name, [...filters.get(name), selectedOption.id]);
            } else if (event.type === 'select2:unselect') {
                filters.set(name, filters.get(name).filter(it => it !== selectedOption.id));
            }
        } else {

            filters.set(name, values);
        } */

        // delete successors and empty params
        updateParams($el);

        console.debug(Array.from(params.entries()).toString());

        try {
            // fetch data from endpoint
            let data = await API.pull(endpointCategories, params, {'method': 'GET'}, 'json');
            updateFields(data);

        } catch (error) {
            console.error(error);
        }
    };

    

    return {
        init: initialize
    }

})($);

export default FileSearchFilter;