import $ from 'jquery';
import 'jquery-once';

/**
 * breadcrumbs 
 * 
 * 
 */

const Breadcrumbs = (function ($) {
  let $breadcrumb, $main_content;

  const initialize = () => {

    // get breadcrumb element
    $breadcrumb = $('.layout-content > #breadcrumbs');
    $main_content = $('.page-main-content');

    // move breadcrumb element and move to hero, product-information ... elements content
    $main_content.once('panasonicBreadcrumbsMove').each(() => {
      const $main_content_moveto = $('> .paragraph--type--rotating-banner:first-child, > .paragraph-hero:first-child, > .paragraph-product-information:first-child, > .paragraph-thank-you:first-child', $main_content);

      $breadcrumb.prependTo($main_content_moveto);
    });

    // define visibility classes regarding contents width
    $('.breadcrumb .breadcrumb-inner', $breadcrumb).once('panasonicBreadcrumbs').each((idx, el) => {
      let $el = $(el);
      if ($el.width() == 0) {
        $el.hide();
        $el.addClass('breadcrumb-hidden')
      }
      else {
        $el.addClass('breadcrumb-visible')
      }
    });

    // iterate over breadcrumb items, change item visibility, arrow, link-text ..
    $('.breadcrumb .breadcrumb-visible .breadcrumb__item', $breadcrumb).once('panasonicBreadcrumbsItems').each((idx, el) => {
      let $item = $(el),
        $item_arrow = $item.find('.breadcrumb__arrow, .breadcrumb__arrow__first'),
        $link = $item.find('.breadcrumb__link');

      const item_inner_width = $item_arrow.outerWidth(true) + $link.width();
      if (item_inner_width > $item.width()) {
        $item_arrow.hide();
        $link.text('...');
      }
      else {
        $item.addClass('item-visible');
      }
    });

    $('.breadcrumb', $breadcrumb).addClass('breadcrumb-visible');
  }

  return {
    init: initialize
  }
})($);

export default Breadcrumbs;
