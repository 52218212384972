import $ from 'jquery';
import 'jquery-once';
import '../../vendor/slick/slick.min'; 

const XColumnTeasers = (function () {
  'use strict';

  // slider selector
  const sliderWrapperClass = '.paragraph-x-column-teasers--slide';
  //const activeSlideClass = '.slide-item';

  let options;
  let defaultOptions = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    slide: '.teaser',
    autoplay: true,
    autoplaySpeed: 5000,
    /* variableWidth: true, */
    responsive: [
      {
        breakpoint: 640,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: false,
        }
      },
    ]
    
  }

  const initialize = function () {


    let $sliderWrapper = $(sliderWrapperClass);


    $sliderWrapper.once('xColumnTeasers').each(function () {

      // get options defined as data attribute data-slick

      let dataOptions = $(this).data('slick');

      $('.field__items', $(this)).once('xColumnTeasersSlider').each(function () {

        // add option for arrows
      defaultOptions.appendArrows = $(this).parents('.paragraph-x-column-teasers__content')

        if (dataOptions) {
          //merge options to defaults
          options = {...defaultOptions, ...dataOptions}
        }
        else {
          // otherwise use defaultså
          options = defaultOptions;
        }
        
        
        $(this).slick(options); 
      });

    })

  };


  return {
    init: initialize
  }

})($);

export default XColumnTeasers;
