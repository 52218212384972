import $ from 'jquery';
import 'jquery-once';
import slick from '../../vendor/slick/slick.min';

/**
 * slick slider for card-slider module
 */
const CardSlider = (() => {
  'use strict';

  let options = {},
    defaultOptions = {
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 1,
      slide: '.teaser',
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    };

  // slider selector
  const sliderWrapperClass = '.paragraph-card-slider';

  const initialize = () => {
    /* $(sliderWrapperClass).on('beforeChange', function(event, slick, currentSlide, nextSlide){
      updateDots(slick, currentSlide, nextSlide);
    }); */

    $(sliderWrapperClass).once('cardSliderWrapper').each(function () {
      let $sliderWrapper = $(this);
      let dataOptions = $sliderWrapper.data('slick');


      $('.field__items', $sliderWrapper).once('cardSlider').each(function () {
        let $slider = $(this);


        if ($('.teaser', $slider).length == 1) {
          $slider.addClass('single-item');
        }

        if (dataOptions) {
          //merge options to defaults
          options = { ...defaultOptions, ...dataOptions }
        }
        else {
          // otherwise use defaultså
          options = defaultOptions;
        }

        $slider.slick(options);
      });

    });
  };

  return {
    init: initialize
  }

})($,  slick);

export default CardSlider;
